<template>
	<div>
		<ux-form ref="form" @submit.prevent="submitLogin">
			<ux-panel>
				<ux-panel-title>
					<div class="panel-title-text">Login</div>
				</ux-panel-title>

				<ux-panel-body>
					<ux-text-field
						autofocus
						label="Email Address"
						:rules="rules.email"
						v-model="email"
					/>

					<ux-text-field
						label="Password"
						type="password"
						:rules="rules.password"
						v-model="password"
					/>
				</ux-panel-body>

				<ux-panel-footer>
					<ux-btn type="submit" color="primary" :loading="loading">Login</ux-btn>

					<div class="flex-spacer"></div>

					<div>
						<ux-btn color="text-primary" :to="redirectPath">Register Now</ux-btn>
						<ux-btn color="text" to="/forgot_password">Forgot Password</ux-btn>
					</div>
				</ux-panel-footer>
			</ux-panel>
		</ux-form>
	</div>
</template>

<script>
import api from '../auth-api';

import alerts from 'common/alerts';

export default {
	data()
	{
		return {
			loading: false,
			email: '',
			password: '',
			rules: {
				email: [ v => !!v || 'Please enter your email address' ],
				password: [ v => !!v || 'Please enter your password' ],
			}
		}
	},

	computed: {
		redirectPath()
		{
			var path = '/registration';
			var redirect = this.$route.query.redirect;

			if (redirect)
				path = `${path}?redirect=${encodeURIComponent(redirect)}`;
			
			return path;
		}
	},

	methods: {
		async submitLogin()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;

			try
			{
				var result = await api.post('/auth/authenticate', { 
					email: this.email,
					password: this.password,
				});

				if (result.data.success)
				{
					await alerts.show('Login success', 'Success', 'success');

					if (this.$route.query.redirect)
						document.location = this.$route.query.redirect;
					else
						document.location = '/profile';
				}
				else
				{
					await alerts.show('Invalid username or password', 'Login failed', 'error');
				}
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>