
import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthMain from 'frontend/auth/comp/AuthMain.vue';
import LoginForm from 'frontend/auth/comp/LoginForm.vue';
import ForgotPassword from 'frontend/auth/comp/ForgotPassword.vue';
import ResetPassword from 'frontend/auth/comp/ResetPassword.vue';
import Registration from 'frontend/auth/comp/Registration.vue';

import 'frontend/ux';

Vue.use(VueRouter);

// setup router
const router = new VueRouter({
	mode: 'history',

	routes: [
		{
			name: 'login-form',
			path: '/login',
			component: LoginForm,
		},
		{
			name: 'forgot-password-form',
			path: '/forgot_password',
			component: ForgotPassword,
		},
		{
			name: 'reset-password-form',
			path: '/reset_password',
			component: ResetPassword,
		},
		{
			name: 'registration-form',
			path: '/registration',
			component: Registration,
		},
	]
});

// initialize authentication screens
const app = new Vue({
	el: '#app-root',
	router,

	render(h) {
		return h(AuthMain);
	}
});