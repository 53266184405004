
import Vue from 'vue';

import Btn from './comp/Button.vue';
import Form from './comp/Form.vue';
import FormField from './comp/FormField.vue';

import TextField from './comp/TextField.vue';
import Textarea from './comp/TextArea.vue';
import Checkbox from './comp/Checkbox.vue';
import Radio from './comp/Radio.vue';
import Select from './comp/Select.vue';
import SelectField from './comp/SelectField.vue';
import Pagination from './comp/Pagination.vue';

import './panel-components';

const comps = {
	Btn, Form, FormField, TextField, Textarea, Checkbox, Radio, Select, SelectField, Pagination,
};

for(var compName in comps)
	Vue.component(`Ux${compName}`, comps[compName]);


