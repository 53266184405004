<template>
	<div>
		<ux-form ref="form" @submit.prevent="submit">
			<ux-panel>
				<ux-panel-title>
					<div class="panel-title-text">Reset Password</div>
				</ux-panel-title>

				<template v-if="valid">
					<ux-panel-body>
						<ux-text-field
							v-model="form.email"
							label="Email Address"
							:rules="rules.email"
						/>

						<ux-text-field
							v-model="form.password"
							type="password"
							label="Password"
							:rules="rules.password"
						/>

						<ux-text-field
							v-model="form.password_confirmation"
							type="password"
							label="Confirm Password"
							:rules="rules.passwordConf"
						/>
					</ux-panel-body>

					<ux-panel-footer>
						<ux-btn v-if="!success" type="submit" color="primary" :loading="loading">Submit</ux-btn>
						<div class="flex-spacer"></div>
						<ux-btn color="text" to="/login">Return To Login</ux-btn>
					</ux-panel-footer>				
				</template>

				<ux-panel-body v-else>
					Invalid link
				</ux-panel-body>
			</ux-panel>
		</ux-form>
	</div>
</template>

<script>
import validate from 'admin/shared/validators';
import alerts from 'common/alerts';

import api from '../auth-api';

export default {
	data()
	{
		return {
			valid: false,
			error: false,
			success: false,
			loading: false,

			form: {
				token: '',
				email: '',
				password: '',
				password_confirmation: '',
			},

			rules: {
				email: [
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],

				password: [ 
					v => !!v || 'Please enter a new password',
					v => validate.password(v) || 'Passwords must be at least six characters long',
				],

				passwordConf: [
					v => v == this.form.password || 'Passwords do not match',
				]
			}
		}
	},

	methods: {
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;
			this.error = false;
			this.success = false;

			try
			{
				// attempt to reset password
				await api.post('/submit_reset_password', this.form);

				// notify user of success
				await alerts.show('Password reset successfully. You will now be redirected to the login form.', 'Success', 'success');
				document.location = '/login';
			}
			catch(err)
			{
				// display error returned from server
				var msg = err.response ? err.response.data.error : err.message;
				this.success = false;
				//this.error = msg;
				//alerts.show(msg, 'Error', 'error');
			}
			finally
			{
				this.loading = false;
			}

		}
	},

	mounted()
	{
		if (window._initData && window._initData.tokenValid)
		{
			this.valid = true;
			this.form = { ...this.form, token: window._initData.tokenValue };
		}
	}
}
</script>