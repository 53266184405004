<template>
	<ux-panel>
		<ux-panel-title>
			<div class="panel-title-text">Registration</div>
		</ux-panel-title>

		<ux-panel-body>
			<ux-form ref="form">
				<div class="row">
					<div class="col-md-6">
						<ux-text-field
							label="First Name"
							:rules="rules.first_name"
							v-model="user.first_name"
						/>
					</div>

					<div class="col-md-6">
						<ux-text-field
							label="Last Name"
							:rules="rules.last_name"
							v-model="user.last_name"
						/>
					</div>

					<div class="col-xs-12">
						<ux-text-field
							label="Company Name"
							v-model="user.company_name"
						/>
					</div>

					<div class="col-xs-12">
						<ux-text-field
							label="Email"
							:rules="rules.email"
							v-model="user.email"
						/>
					</div>

					<div class="col-md-6">
						<ux-text-field
							label="Phone Number"
							:rules="rules.phone1"
							v-model="user.phone1"
						/>
					</div>

					<div class="col-md-6">
						<ux-text-field
							label="Alternate Phone"
							v-model="user.phone2"
						/>
					</div>

					<div class="col-md-6">
						<ux-text-field
							type="password"
							label="Password"
							:rules="rules.password"
							v-model="user.password"
						/>
					</div>

					<div class="col-md-6">
						<ux-text-field
							type="password"
							label="Confirm Password"
							:rules="rules.password_confirmation"
							v-model="user.password_confirmation"
						/>
					</div>

				</div>
			</ux-form>
		</ux-panel-body>

		<ux-panel-footer>
			<ux-btn color="primary" :loading="saving" @click="submit">Submit</ux-btn>
			<div class="flex-spacer"></div>
			<ux-btn color="text" :disabled="saving" to="/login">Back</ux-btn>
		</ux-panel-footer>
	</ux-panel>
</template>

<script>
import api from '../auth-api';
import alerts from 'common/alerts';
import validate from 'common/validators';

export default {
	data()
	{
		return {
			saving: false,

			user: {
				first_name: '',
				last_name: '',
				company_name: '',
				email: '',
				phone1: '',
				phone2: '',
				password: '',
				password_confirmation: '',
			},

			rules: {
				first_name: [ v => !!v || 'Please enter your first name' ],
				last_name: [ v => !!v || 'Please enter your last name' ],
				email: [ 
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],
				phone1: [ v => !!v || 'Please enter your primary phone number' ],
				password: [ 
					v => !!v || 'Please enter your password', 
					v => validate.password(v) || 'Passwords must be six characters or longer'
				],
				password_confirmation: [ 
					v => !!v || 'Please re-enter your password',
					v => v == this.user.password || 'Passwords do not match' 
				],
			}
		}
	},

	methods: {
		async submit()
		{
			var redirect = this.$route.query.redirect;

			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				await api.post('/api/registration/submit', { user: this.user });
				await alerts.show('Registration completed successfully!', 'Success', 'success');

				if (redirect)
					document.location = redirect;
				else
					document.location = '/profile';
			}
			finally
			{
				this.saving = false;
			}
		}
	}
}
</script>