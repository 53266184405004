<template>
	<label class="ux-radio" :class="{ 'is-disabled': disabled }" @click.prevent="updateValue">
		<input type="radio" :checked="checked" :disabled="disabled" @input="updateValue">
		<span class="ux-radio-graphic"></span>
		<span v-if="!hideLabel" class="ux-radio-label">{{ label }}</span>
	</label>	
</template>

<script>
export default {
    model: {
        prop: 'data',
        event: 'input',
	},
	
    props: {
        hideLabel: { type: Boolean, default: false },
        value: { default: null },
        data: { default: '' },
        label: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
    },
	
	computed: {
        checked()
        {
			return this.data == this.value;
        },
    },
	
	methods: {
		updateValue()
		{
			this.$emit('input', this.value);
		}
    }
}
</script>